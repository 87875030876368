import React from 'react';
import {observer} from 'mobx-react';
import {GenericModal} from '@techsee/techsee-ui-common/lib/modals/generic';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {IForwardBirthCertificateReportModalController} from './controller';
import {DefaultModalHeader} from '@techsee/techsee-ui-common/lib/modals/generic/default-modal-header';
import {TagsPanel} from '@techsee/techsee-ui-common/lib/tags-panel';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {SecondaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/secondary';
import {FormInput} from '@techsee/techsee-ui-common/lib/forms/input';
import {
    Spinner,
    SpinnerBackgroundColors,
    SpinnerColors,
    SpinnerSizes,
    SpinnerTypeOptions
} from '@techsee/techsee-ui-common/lib/spinner';

import './style.scss';

export interface IForwardBirthCertificateReportModal {
    ctrl: IForwardBirthCertificateReportModalController;
}

@observer
export class ForwardBirthCertificateReportModal extends TechseeBaseComponent<IForwardBirthCertificateReportModal> {
    renderInitialState() {
        const {
            translate,
            emailInput,
            onEmailInputSubmit,
            onEmailInputChange,
            onForwardCertificateReportButtonSubmit,
            onEmailTagRemove,
            emailTags,
            emails,
            providedEmailIsInvalid
        } = this.props.ctrl;

        return (
            <form className='emails-form'>
                <p>{translate('REACT.WIFI_ANALYZER.FORWARD_BIRTH_CERTIFICATE_REPORT_MODAL_DESCRIPTION')}</p>
                {emails.length > 0 && (
                    <>
                        <p className='email-description'>
                            {translate('REACT.WIFI_ANALYZER.FORWARD_BIRTH_CERTIFICATE_REPORT_MODAL_ADD_EMAIL_TEXT')}
                        </p>
                    </>
                )}
                <TagsPanel
                    className={'tag-panel'}
                    tags={emailTags}
                    onTagClick={onEmailTagRemove}
                    onDeleteClick={onEmailTagRemove}
                />
                <div className='email-tags-input-container'>
                    <FormInput
                        uid={'input-new-tag'}
                        className={'email-input'}
                        model={emailInput}
                        onChange={onEmailInputChange}
                        onEnterKey={onEmailInputSubmit}
                    />
                    <SecondaryButton onClick={onEmailInputSubmit} className={'add-email-button'}>
                        {translate('REACT.WIFI_ANALYZER.FORWARD_BIRTH_CERTIFICATE_REPORT_MODAL_ADD_EMAIL_BUTTON')}
                    </SecondaryButton>
                    {providedEmailIsInvalid && (
                        <p className='error-message'>
                            {translate('REACT.WIFI_ANALYZER.FORWARD_BIRTH_CERTIFICATE_REPORT_MODAL_INVALID_EMAIL')}
                        </p>
                    )}
                </div>
                <PrimaryButton onClick={onForwardCertificateReportButtonSubmit} className={'forward-button'}>
                    {translate('REACT.WIFI_ANALYZER.FORWARD_BIRTH_CERTIFICATE_REPORT_MODAL_SEND_BUTTON')}
                </PrimaryButton>
            </form>
        );
    }

    renderSendingState() {
        return (
            <Spinner
                spinnerType={SpinnerTypeOptions.CIRCULAR}
                sizeName={SpinnerSizes.Small}
                backgroundColorName={SpinnerBackgroundColors.Transparent}
                colorName={SpinnerColors.Blue}
            />
        );
    }

    renderSentState() {
        const {emails, translate} = this.props.ctrl;

        return (
            <p className='emails-sent'>
                {translate('REACT.WIFI_ANALYZER.FORWARD_BIRTH_CERTIFICATE_REPORT_MODAL_SENT_MESSAGE')}
                {emails.map((email, index) => (
                    <React.Fragment key={index}>
                        <br />
                        <span className='email'>{email}</span>
                    </React.Fragment>
                ))}
                <br />
                {translate('REACT.WIFI_ANALYZER.FORWARD_BIRTH_CERTIFICATE_REPORT_MODAL_CAN_CLOSE')}
            </p>
        );
    }

    renderErrorState() {
        const {translate} = this.props.ctrl;

        return (
            <p className='emails-send-error'>
                {translate('REACT.WIFI_ANALYZER.FORWARD_BIRTH_CERTIFICATE_REPORT_MODAL_SEND_ERROR')}
            </p>
        );
    }

    renderContent() {
        const {forwardReportState} = this.props.ctrl;

        return (
            <div className={'content'}>
                {forwardReportState === 'init' && this.renderInitialState()}
                {forwardReportState === 'sending' && this.renderSendingState()}
                {forwardReportState === 'sent' && this.renderSentState()}
                {forwardReportState === 'error' && this.renderErrorState()}
            </div>
        );
    }

    renderInternal() {
        const {translate, hide, isVisible} = this.props.ctrl;

        const ModalContent = this.renderContent();

        return (
            <GenericModal
                className={'forward-birth-certificate-modal'}
                header={() => (
                    <DefaultModalHeader>
                        {translate('REACT.WIFI_ANALYZER.FORWARD_BIRTH_CERTIFICATE_REPORT_MODAL_TITLE')}
                    </DefaultModalHeader>
                )}
                content={() => ModalContent}
                toolbar={() => <React.Fragment />}
                onHideHandler={hide}
                isCloseVisible={true}
                closeOnEscape={true}
                show={isVisible}></GenericModal>
        );
    }
}
