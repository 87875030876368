import {observable, action, computed, toJS, runInAction} from 'mobx';
import {ITranslate} from '../../../services/LocalizationService';
import {IFieldModel, SimpleFieldModel} from '@techsee/techsee-ui-common/lib/forms/_shared/simple-field-model';
import {ITag} from '@techsee/techsee-ui-common/lib/tags-panel';
import {getRootStore} from '../../../app.bootstrap';

export interface IForwardBirthCertificateReportModalController {
    readonly translate: ITranslate;
    readonly isVisible: boolean;
    readonly emailTags: ITag[];
    readonly forwardReportState: 'init' | 'sending' | 'sent' | 'error';
    readonly emails: Array<string>;
    readonly providedEmailIsInvalid: boolean;

    emailInput: IFieldModel;

    show(scanId: string): void;
    hide(): void;
    onEmailInputSubmit(): void;
    onEmailTagRemove(tag: ITag): void;
    onEmailInputChange(): void;
    onForwardCertificateReportButtonSubmit(): void;
}

export class ForwardBirthCertificateReportModalController implements IForwardBirthCertificateReportModalController {
    private readonly _translate: ITranslate;

    @observable private _scanId: string;
    @observable private _isVisible: boolean;
    @observable private _forwardReportState: 'init' | 'sending' | 'sent' | 'error';
    @observable private _emailTags: ITag[];
    @observable private _newEmailHasBeenTyped: boolean;

    emailInput: IFieldModel;

    constructor(translate: ITranslate) {
        this._translate = translate;

        this._scanId = '';
        this._isVisible = false;
        this._forwardReportState = 'init';
        this._emailTags = [];
        this._newEmailHasBeenTyped = false;

        this.emailInput = new SimpleFieldModel({value: ''});

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.onEmailInputSubmit = this.onEmailInputSubmit.bind(this);
        this.onEmailTagRemove = this.onEmailTagRemove.bind(this);
        this.onEmailInputChange = this.onEmailInputChange.bind(this);
        this.onForwardCertificateReportButtonSubmit = this.onForwardCertificateReportButtonSubmit.bind(this);
    }

    get translate() {
        return this._translate;
    }

    @action
    show(scanId: string): void {
        this._scanId = scanId;
        this._isVisible = true;
        this._forwardReportState = 'init';
        this._emailTags = [];
        this._newEmailHasBeenTyped = false;
        this.emailInput.setValue('');
    }

    @action
    hide(): void {
        this._isVisible = false;
    }

    @action
    onEmailInputSubmit(): void {
        this._newEmailHasBeenTyped = true;
        if (!this.providedEmailIsInvalid) {
            const emailTag = {id: this.emailInput.value.toString(), text: this.emailInput.value.toString()};

            if (!this._emailTags.find((existingTag: ITag) => existingTag.id === emailTag.id)) {
                this._emailTags.push(emailTag);
            }

            this._newEmailHasBeenTyped = false;
            this.emailInput.setValue('');
        }
    }

    @action
    async onForwardCertificateReportButtonSubmit(): Promise<void> {
        if (this._emailTags.length <= 0) {
            return;
        }

        this._forwardReportState = 'sending';

        try {
            await getRootStore().wifiAnalyzerService.forwardBirthCertificateReport(this._scanId, this.emails);

            runInAction(() => (this._forwardReportState = 'sent'));
        } catch (error) {
            runInAction(() => (this._forwardReportState = 'error'));
        }
    }

    @action
    onEmailTagRemove(tag: ITag): void {
        this._emailTags = this._emailTags.filter((emailTag: ITag) => emailTag.id !== tag.id);
    }

    @action
    onEmailInputChange(): void {
        this._newEmailHasBeenTyped = false;
    }

    @computed
    get isVisible(): boolean {
        return this._isVisible;
    }

    @computed
    get forwardReportState(): 'init' | 'sending' | 'sent' | 'error' {
        return this._forwardReportState;
    }

    @computed
    get emailTags(): ITag[] {
        return toJS(this._emailTags);
    }

    @computed
    get emails(): Array<string> {
        return this._emailTags.map((emailTag: ITag) => emailTag.text);
    }

    @computed
    get providedEmailIsInvalid(): boolean {
        if (this._newEmailHasBeenTyped) {
            return (
                !this.emailInput.value ||
                !this.emailInput.value.toString().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
            );
        }

        return false;
    }
}
