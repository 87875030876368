import React, {Component} from 'react';
import {uuidv4} from '@techsee/techsee-common/lib/core/uuid';

import './styles.scss';

export interface ICopilot {
    init(copilotElement: HTMLDivElement): void;
    username?: string;
}

const ProductFruitsContainer = React.lazy(() => import('../product-fruit/ProductFruitsContainer'));

export class Copilot extends Component<ICopilot> {
    private readonly copilotRef: React.RefObject<HTMLDivElement>;

    constructor(props: ICopilot) {
        super(props);
        this.copilotRef = React.createRef();
    }

    componentDidMount() {
        this.initCopilot();
    }

    initCopilot = () => {
        const copilotElement = this.copilotRef.current;

        if (copilotElement) {
            this.props.init(copilotElement);
        }
    };

    render() {
        return (
            <>
                {PRODUCT_FRUIT_WORKSPACE_CODE && (
                    <React.Suspense fallback={<></>}>
                        <ProductFruitsContainer
                            username={this.props.username ?? uuidv4()}
                            workspaceCode={PRODUCT_FRUIT_WORKSPACE_CODE}
                        />
                    </React.Suspense>
                )}
                <div className={'copilot-container'} ref={this.copilotRef}></div>
            </>
        );
    }
}

export default Copilot;
