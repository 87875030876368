import React from 'react';
import {inject, IReactComponent, observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import LeftBar from '../../states/invite-new/left-bar/index';
import BottomBar from './bottom-bar/index';
import {IMainLayoutController} from './layout.controller';
import {MainLayoutNotification} from './notification-toaster/main-layout-notification';
import AgentSettings from './agent-settings';
import MainTopBar from './main-top-bar/component';
import Copilot from '../../components/copilot';
import {IInviteFlowManager} from '../../states/invite-new/_contracts/InviteContracts';
import get from 'lodash/get';

import './style.scss';

interface IAppLayout {
    mainLayoutController: IMainLayoutController;
    topBarComponent: IReactComponent;
    mainContentComponent: IReactComponent;
    inviteFlowManager: IInviteFlowManager;
}

@inject('mainLayoutController')
@inject('inviteFlowManager')
@observer
export class AppLayoutComponent extends TechseeBaseComponent<IAppLayout> {
    renderInternal() {
        const {mainLayoutController, inviteFlowManager} = this.props;

        this.addCssClass('app-root-layout');
        this.addCssClass('use-common-styles');
        this.addCssClassIf('no-left-bar', !mainLayoutController.isLeftBarVisible);

        const TopBarComponent = this.props.topBarComponent;
        const MainComponent = this.props.mainContentComponent;

        return (
            <div className={this.renderCssClasses()}>
                <MainTopBar topBarComponent={TopBarComponent} accountLogo={mainLayoutController.accountLogo} />
                {mainLayoutController.isLeftBarVisible && <LeftBar />}
                <div className='app-middle-area'>
                    <main className='app-main-content'>
                        <MainLayoutNotification mainLayoutController={mainLayoutController} />
                        <MainComponent />
                    </main>
                    {inviteFlowManager.copilotSettings.enabled && (
                        <Copilot
                            username={get(inviteFlowManager, 'accountId', undefined)}
                            init={inviteFlowManager.initCopilot}
                        />
                    )}
                    <BottomBar />
                </div>

                <AgentSettings />
            </div>
        );
    }
}

export default AppLayoutComponent;
