'use strict';
import {getRootStore} from '../../_react_/app.bootstrap';

const APP_TYPES = {
    DESKTOP_WEB: 'DESKTOP',
    DESKTOP_APP: 'DESKTOP_APP',
    MOBILE_WEB: 'MOBILE_WEB',
    MOBILE_APP: 'APPLICATION'
};

import UAParser from '@techsee/techsee-common/lib/ua-parser';
import TsUtils from '@techsee/techsee-common/lib/utils';

async function _parseUserAgent(userAgent) {
    const parser = new UAParser(userAgent);
    const uaData = await parser.getResult().withClientHints();

    return `${uaData.browser.name} v${uaData.browser.version}`;
}

export class DbService {
    constructor(DS, DSHttpAdapter, tsUrlConfig, $window) {
        'ngInject';

        this.urlConfig = tsUrlConfig;

        // Expose the DS object
        this.DS = DS;

        const baseRoot = this.urlConfig.get('API_URL');
        const statsApiUrl = STATS_API_URL ? this.urlConfig.get('STATS_API_URL') : baseRoot;
        const serviceBackendUrl = API_URL ? TsUtils.getServiceBackendUrl(API_URL, $window.location.hostname) : baseRoot;

        DS.defaults.basePath = baseRoot + 'api';

        if (ENV.production) {
            // Disable console logs
            DSHttpAdapter.defaults.log = false;
        }

        this.Auth = DS.defineResource({
            name: 'auth',
            basePath: baseRoot,
            endpoint: '/',
            actions: {
                loginSubUser: {
                    method: 'POST',
                    pathname: '/api/auth/subUser'
                },
                logout: {
                    method: 'POST'
                }
            }
        });

        this.Token = DS.defineResource({
            name: 'token',
            basePath: baseRoot,
            endpoint: '/api',
            actions: {
                auth: {
                    method: 'POST'
                }
            }
        });

        this.User = DS.defineResource({
            name: 'users',
            idAttribute: '_id',
            actions: {
                observableByAccount: {
                    method: 'GET'
                },
                joinSessionValidation: {
                    method: 'GET'
                },
                byAccountRole: {
                    method: 'GET'
                },
                byAccountGroupManager: {
                    method: 'GET'
                },
                isEmailAvailable: {
                    method: 'GET'
                },
                groupPeers: {
                    method: 'GET'
                },
                acceptTerms: {
                    method: 'PUT'
                },
                updateRanConnectivityTests: {
                    method: 'PUT'
                },
                withGroupsByIds: {
                    method: 'GET'
                },
                getAccountChannelData: {
                    method: 'GET'
                }
            }
        });

        this.WifiAnalyzer = DS.defineResource({
            name: 'wifiAnalyzer',
            idAttribute: '_id',
            actions: {
                bulk: {
                    method: 'GET'
                },
                isExist: {
                    method: 'GET'
                },
                forwardBirthCertificateReport: {
                    method: 'POST',
                    pathname: 'birthCertificateReport/forward'
                }
            }
        });

        this.Account = DS.defineResource({
            name: 'accounts',
            idAttribute: '_id',
            actions: {
                isNewInviteEnabled: {
                    method: 'GET'
                },
                sendReactivationDetails: {
                    method: 'POST'
                },
                enqueueRevokeAccountRequest: {
                    method: 'POST'
                },
                manualLoginAbilityAssertion: {
                    method: 'GET'
                }
            }
        });

        this.Vj = DS.defineResource({
            name: 'vj',
            endpoint: '/v2/vj/flow',
            actions: {
                getFlows: {
                    method: 'GET'
                }
            }
        });

        this.OTT = DS.defineResource({
            name: 'ott',
            endpoint: 'ott',
            actions: {
                redeem: {
                    method: 'POST'
                },
                createOneTimeToken: {
                    method: 'POST',
                    pathname: 'create'
                }
            }
        });

        this.Group = DS.defineResource({
            name: 'groups',
            idAttribute: '_id',
            relations: {
                belongsTo: {
                    accounts: {
                        localField: 'accounts',
                        localKey: 'accountId',
                        parent: true
                    }
                }
            },
            actions: {
                byManager: {
                    method: 'GET'
                }
            }
        });

        this.Reports = DS.defineResource({
            name: 'reports',
            endpoint: 'v2/reports',
            idAttribute: '_id',
            actions: {
                meetings: {
                    method: 'GET'
                },
                groupReports: {
                    method: 'GET'
                },
                userReports: {
                    method: 'GET'
                },
                agentPerformance: {
                    method: 'GET'
                }
            }
        });

        this.Email = DS.defineResource({
            name: 'email',
            endpoint: 'email',
            actions: {
                send: {method: 'POST'},
                visualJourney: {method: 'POST'},
                clientAsDashboard: {method: 'POST'},
                observerInvitation: {method: 'POST'}
            }
        });

        this.WhatsApp = DS.defineResource({
            name: 'whatApp',
            endpoint: 'whatsapp',
            actions: {
                sendTemplate: {method: 'POST'},
                status: {method: 'GET'}
            }
        });

        this.Sms = DS.defineResource({
            name: 'sms',
            idAttribute: '_id',
            endpoint: 'sms',
            actions: {
                validatephonenumber: {method: 'POST'},
                clientAsDashboard: {method: 'POST'},
                observerInvitation: {method: 'POST'},
                visualJourney: {method: 'POST'},
                warmTransfer: {method: 'POST'}
            }
        });

        this.PrimarySMS = DS.defineResource({
            name: 'primarySMS',
            idAttribute: '_id',
            endpoint: 'sms/primary'
        });

        this.SecondarySMS = DS.defineResource({
            name: 'secondarySMS',
            idAttribute: '_id',
            endpoint: 'sms/secondary'
        });

        this.SMSStatus = DS.defineResource({
            name: 'SMSStatus',
            idAttribute: '_id',
            endpoint: 'sms/status'
        });

        this.CoBrowsing = DS.defineResource({
            name: 'coBrowsing',
            idAttribute: '_id',
            endpoint: 'coBrowsing',
            actions: {
                startSession: {method: 'POST'}
            }
        });

        this.Rooms = DS.defineResource({
            name: 'rooms',
            idAttribute: '_id',
            beforeCreate: async (resource, data) => {
                let type = '';
                const environmentDetect = getRootStore().environmentService;
                const displayTabletAsDesktop = getRootStore().displayTabletAsDesktop;

                if (environmentDetect.isMobile(displayTabletAsDesktop)) {
                    type = APP_TYPES.MOBILE_WEB;
                } else {
                    type = APP_TYPES.DESKTOP_WEB;
                }

                data.agentType = type;
                data.agentClient = await _parseUserAgent(window.navigator.userAgent);

                return Promise.resolve(data);
            },
            actions: {
                active: {
                    method: 'GET'
                },
                getClientDeviceDetails: {
                    method: 'GET'
                },
                lastPhotoStreamReason: {
                    method: 'GET'
                },
                byCustomerId: {
                    method: 'GET'
                },
                code: {
                    method: 'GET'
                },
                shortId: {
                    method: 'GET'
                },
                createUrlForUpload: {
                    method: 'POST'
                },
                uploadClipboard: {
                    method: 'POST'
                },
                setReportedField: {
                    method: 'PUT'
                },
                mediaPermissionAllow: {
                    method: 'PUT'
                },
                allowNewClient: {
                    method: 'POST'
                },
                eveReportedField: {
                    method: 'GET'
                },
                startRecording: {
                    method: 'POST',
                    endpoint: 'rooms/mediaServer'
                },
                stopRecording: {
                    method: 'POST',
                    endpoint: 'rooms/mediaServer'
                },
                closeMediaServerSession: {
                    method: 'POST',
                    endpoint: 'rooms/mediaServer'
                },
                customerGeolocation: {
                    method: 'POST'
                },
                muteAudio: {
                    method: 'POST'
                },
                dashboardV3Url: {
                    method: 'GET'
                },
                updateWifiScanResult: {
                    method: 'PUT'
                }
            },
            methods: {
                setCustomer: function (data) {
                    return this.DSUpdate(data, {
                        endpoint: 'rooms/customer'
                    });
                },

                start: function () {
                    return this.DSUpdate(
                        {},
                        {
                            endpoint: 'rooms/start'
                        }
                    );
                },

                initOpentok: function (userType) {
                    return this.DSUpdate(
                        {userType},
                        {
                            endpoint: 'rooms/opentok'
                        }
                    );
                },

                initMediaServer: function (userType, audioSupport) {
                    return this.DSUpdate(
                        {userType, audioSupport},
                        {
                            endpoint: 'rooms/mediaServer'
                        }
                    );
                },

                connect: function () {
                    return this.DSUpdate(
                        {},
                        {
                            endpoint: 'rooms/connect'
                        }
                    );
                },

                end: function (closedBy, reason) {
                    return this.DSUpdate(
                        {
                            closedBy: closedBy,
                            reasonForClosing: reason
                        },
                        {
                            endpoint: 'rooms/end'
                        }
                    );
                },

                changeAgent: function (agent) {
                    return this.DSUpdate(
                        {agent: agent},
                        {
                            endpoint: 'rooms/agent'
                        }
                    );
                }
            }
        });

        this.RoomsVideoReceived = DS.defineResource({
            name: 'rooms/videoReceived',
            idAttribute: '_id',
            beforeCreate: async (resource, data) => {
                let type = '';
                const environmentDetect = getRootStore().environmentService;
                const displayTabletAsDesktop = getRootStore().displayTabletAsDesktop;

                if (environmentDetect.isMobile(displayTabletAsDesktop)) {
                    type = APP_TYPES.MOBILE_WEB;
                } else {
                    type = APP_TYPES.DESKTOP_WEB;
                }

                data.agentType = type;
                data.agentClient = await _parseUserAgent(window.navigator.userAgent);

                return Promise.resolve(data);
            }
        });

        this.RoomsChangeAgent = DS.defineResource({
            name: 'rooms/agent',
            idAttribute: '_id',
            beforeCreate: async (resource, data) => {
                let type = '';
                const environmentDetect = getRootStore().environmentService;
                const displayTabletAsDesktop = getRootStore().displayTabletAsDesktop;

                if (environmentDetect.isMobile(displayTabletAsDesktop)) {
                    type = APP_TYPES.MOBILE_WEB;
                } else {
                    type = APP_TYPES.DESKTOP_WEB;
                }

                data.agentType = type;
                data.agentClient = await _parseUserAgent(window.navigator.userAgent);

                return Promise.resolve(data);
            }
        });

        this.History = DS.defineResource({
            basePath: serviceBackendUrl + '/api',
            name: 'history',
            endpoint: 'history',
            idAttribute: '_id',
            actions: {
                room: {
                    method: 'GET'
                },
                byCustomerId: {
                    method: 'GET'
                },
                byCustomerNumber: {
                    method: 'GET'
                },
                byCustomerIdOrNumber: {
                    method: 'GET'
                }
            },
            searchByTagsCount: (data) =>
                this.History.find('searchByTags/count', {
                    params: data
                }),
            searchByTags: (data) =>
                this.History.find('searchByTags/search', {
                    params: data
                })
        });

        this.VideoRecordings = DS.defineResource({
            name: 'recording',
            endpoint: 'recording',
            actions: {
                byCustomerId: {
                    method: 'GET'
                },
                byCustomerNumber: {
                    method: 'GET'
                }
            }
        });

        this.HistoryImage = DS.defineResource({
            name: 'history-images',
            endpoint: 'history/IMAGE',
            idAttribute: '_id',
            actions: {
                revision: {
                    method: 'PUT'
                },
                update: {
                    method: 'PUT'
                },
                record: {
                    method: 'GET'
                },
                resign: {
                    method: 'PUT'
                },
                getAll: {
                    method: 'GET'
                }
            }
        });

        this.HistoryVideo = DS.defineResource({
            name: 'history-videos',
            endpoint: 'history/VIDEO',
            idAttribute: '_id',
            actions: {
                revision: {
                    method: 'PUT'
                },
                tags: {
                    method: 'PUT'
                },
                record: {
                    method: 'GET'
                },
                resign: {
                    method: 'PUT'
                }
            }
        });

        this.EventLog = DS.defineResource({
            basePath: statsApiUrl,
            name: 'eventLog',
            endpoint: '/api/eventLog',
            idAttribute: '_id',
            deserialize: () => ({_id: ''})
        });

        this.QOSLog = DS.defineResource({
            basePath: statsApiUrl,
            name: 'qosLog',
            endpoint: '/api/clientQOS',
            idAttribute: '_id',
            deserialize: () => ({_id: ''})
        });

        this.BandwidthMeasurement = DS.defineResource({
            name: 'bandwidthMeasurement',
            endpoint: 'bandwidthMeasurement',
            idAttribute: '_id',
            actions: {
                add: {
                    method: 'POST'
                }
            }
        });

        this.Observations = DS.defineResource({
            name: 'observations',
            endpoint: 'observations',
            idAttribute: '_id',
            actions: {
                add: {
                    method: 'POST'
                },
                guestObserverLogin: {
                    method: 'POST'
                },
                guestObserverDetails: {
                    method: 'GET'
                },
                update: {
                    method: 'PUT'
                }
            }
        });

        this.Resource = DS.defineResource({
            name: 'resources',
            endpoint: 'resources',
            idAttribute: '_id',
            actions: {
                resign: {
                    method: 'PUT'
                },
                default: {
                    method: 'GET'
                }
            }
        });

        this.System = DS.defineResource({
            name: 'sys',
            endpoint: 'sys',
            actions: {
                adminUrl: {
                    method: 'GET'
                },
                version: {
                    idAttribute: 'release',
                    method: 'GET'
                }
            }
        });

        this.Location = DS.defineResource({
            name: 'location',
            endpoint: 'location',
            actions: {
                address: {
                    method: 'GET'
                }
            }
        });

        this.Survey = DS.defineResource({
            name: 'surveys',
            endpoint: 'surveys',
            idAttribute: '_id',
            actions: {
                questions: {
                    method: 'GET'
                },
                update: {
                    method: 'PUT'
                }
            }
        });

        this.S3 = DS.defineResource({
            name: 's3',
            endpoint: 's3',
            idAttribute: 'fileName',
            actions: {
                signHistory: {
                    method: 'POST',
                    endpoint: 's3/history'
                },
                // library sign
                sign: {
                    method: 'POST',
                    endpoint: 's3/library'
                }
            }
        });

        this.Scanner = DS.defineResource({
            name: 'scanners',
            endpoint: 'scanners',
            idAttribute: '_id',
            actions: {
                scan: {
                    method: 'POST'
                }
            }
        });

        this.ShortUrl = DS.defineResource({
            name: 'shorturl',
            endpoint: 'shorturl',
            idAttribute: 'url'
        });

        this.Smart = DS.defineResource({
            name: 'smart',
            endpoint: 'smart',
            idAttribute: '_id',
            actions: {
                analyze: {
                    method: 'POST'
                },
                getDevices: {
                    method: 'GET'
                },
                analysisFeedback: {
                    method: 'PUT'
                },
                issues: {
                    method: 'GET'
                },
                solutions: {
                    method: 'GET'
                },
                solutionAdditionalImages: {
                    method: 'GET'
                }
            }
        });

        this.CustomStrings = DS.defineResource({
            name: 'customStrings',
            endpoint: 'customStrings',
            idAttribute: '_id',
            actions: {
                byAccount: {
                    method: 'GET'
                }
            }
        });

        this.SupportedBrowsers = DS.defineResource({
            name: 'supportedBrowsers',
            idAttribute: '_id',
            actions: {
                isSupported: {
                    method: 'GET'
                },
                isSupportedByAccount: {
                    method: 'GET'
                }
            }
        });

        this.SSO = DS.defineResource({
            name: 'sso',
            idAttribute: '_id',
            actions: {
                dashboardInviteViewLoaded: {
                    method: 'POST'
                },
                oAuthLoginCallback: {
                    method: 'POST',
                    pathname: 'oauth/callback/login'
                }
            }
        });

        this.SpeedtestServers = DS.defineResource({
            name: 'speedtestServers',
            endpoint: 'speedtestServers',
            idAttribute: '_id',
            actions: {
                byServerId: {
                    method: 'GET'
                }
            }
        });

        this.PendingRoom = DS.defineResource({
            name: 'pendingRooms',
            endpoint: 'pendingRooms',
            actions: {
                activatePendingRoom: {
                    method: 'POST'
                }
            }
        });
    }
}
